import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { IFilterInfo } from 'src/app/shared/interfaces/filter/IFilterInfo';
import { FiltersService } from 'src/app/shared/services/filters/filters.service';
import { FiltersHttpService } from 'src/app/shared/services/http/filters-http/filters-http.service';
import { HitApi } from '../../../classes/HitApi';
import { IDropdownData } from '../../../interfaces/dropdown-data/IDropdownData';
import { IHitApi } from '../../../interfaces/hit-api/IHitApi';

@Component({
    selector: 'app-single-select-dropdown',
    templateUrl: './single-select-dropdown.component.html',
    styleUrls: ['./single-select-dropdown.component.sass']
})
export class SingleSelectDropdownComponent implements OnInit, OnChanges {
    @Input() filterInfo: IFilterInfo;
    @Input() selectedValue: string;
    @Input() loadingMessage: string;
    @Input() refreshListing: BehaviorSubject<boolean>;
    @Input() clearable: boolean = true;
    @Input() isCaching: boolean;
    @Input() dropdownLabel: string;
    @Input() inputs: any;
    @Input() apiInputCallback: Function;
    @Input() appendTo: string;

    //If date range is just to be shown as a dummy, set its value to true
    @Input() dummy: boolean;
    @Output() afterResponse = new EventEmitter();

    // tslint:disable-next-line: no-output-native
    @Output() change = new EventEmitter();
    @Output() dropDownRef = new EventEmitter();
    bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
    dataBuffer: IDropdownData[] = [];
    uniqueIdentity: symbol;
    filteredList: IDropdownData[] = [];
    showLoading = false;
    loading = false;
    valueChanges = false;
    isOpen = false;
    refresh: boolean = false;

    constructor(
        private filtersHttpService: FiltersHttpService,
        private ngZone: NgZone,
        private filtersService: FiltersService
    ) {}

    ngOnInit(): void {
        this.handleBasics();
    }

    handleBasics() {
        if (this.filterInfo && !this.filterInfo.placeholder) {
            this.filterInfo.placeholder = 'Select ' + this.filterInfo.label;
        }

        if (this.filterInfo && this.filterInfo.listData) {
            this.filteredList = this.filterInfo.listData;
        }
        if (
            this.filterInfo &&
            this.filterInfo.apiInfo &&
            !this.dummy &&
            !this.filterInfo.listData
        ) {
            this.hitApi();
        }
        if (this.refreshListing) {
            this.refreshListing.subscribe((res) => {
                if (res) {
                    if (this.filterInfo.apiInfo) {
                        this.refresh = true;
                        this.hitApi();
                    } else {
                        this.selectedValue = null;
                    }
                }
            });
        }
    }

    selectionChange() {
        this.valueChanges = true;
    }

    onScrollToEnd() {
        this.fetchMore();
    }

    onScroll({ end }) {
        if (
            this.loading ||
            (this.filteredList &&
                this.filteredList.length <= this.dataBuffer.length)
        ) {
            return;
        }
        if (
            end + this.numberOfItemsFromEndBeforeFetchingMore >=
            this.dataBuffer.length
        ) {
            this.fetchMore();
        }
    }

    ngOnChanges(change) {
        if (change.filterInfo) {
            this.filterInfo = change.filterInfo.currentValue;
            this.handleBasics();
        }
        if (change.selectedValue) {
            this.selectedValue = change.selectedValue.currentValue;
        }
    }

    private fetchMore() {
        if (this.filteredList) {
            const len = this.dataBuffer.length;
            const more = this.filteredList.slice(len, this.bufferSize + len);
            this.loading = true;
            // using timeout here to simulate backend API delay
            this.loading = false;
            this.dataBuffer = this.dataBuffer.concat(more);
        }
    }

    emitChange(type) {
        if (type === 'close') {
            if (this.valueChanges) {
                this.change.emit(this.selectedValue);
                this.valueChanges = false;
            }
        } else if (type === 'clear') {
            this.selectedValue = null;
            this.change.emit(this.selectedValue);
        }
    }

    hitApi() {
        this.filteredList = [];
        this.loading = true;
        this.showLoading = true;
        if (this.filterInfo.apiInfo) {
            const args: IHitApi = Helper.generateHitApiConfig(
                this.filterInfo.apiInfo
            );
            if (this.inputs) {
                args.input = this.inputs;
            } else if (this.apiInputCallback) {
                args.input = this.apiInputCallback();
            } else {
                args.input = {};
            }
            args.config.isCached = this.isCaching;
            args.function = this.bindData.bind(this);
            args.endFunction = () => {
                this.showLoading = false;
                this.loading = false;
            };
            new HitApi(args, this.filtersHttpService, this.ngZone).hitApi();
        }
    }

    bindData(response) {
        this.showLoading = false;
        this.loading = false;
        if (Array.isArray(response)) {
            this.filteredList = response;
        } else {
            this.filteredList = response.dataList;
        }

        this.afterResponse.emit(this.filteredList);
        if (
            (!this.selectedValue &&
                response.dataMap &&
                response.dataMap['defaultValue']) ||
            (this.refresh &&
                response.dataMap &&
                response.dataMap['defaultValue'])
        ) {
            this.selectedValue = response.dataMap['defaultValue'];
        }
        if (
            !this.refresh &&
            response.dataMap &&
            response.dataMap['defaultValue'] &&
            this.filterInfo.filterId
        ) {
            this.filtersService.filtersDefaultValue.set(
                this.filterInfo.filterId,
                {
                    value: response.dataMap['defaultValue'],
                    filterInfo: this.filterInfo
                }
            );
        }
        this.change.emit(this.selectedValue);
    }
}
