<div class="container">
    <div class="form-container">
        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTabIndex">
            <mat-tab [label]="Tab.USER_BASED">
                <div class="tw-py-5">
                    <app-modal-info-template
                        [infoData]="infoData"
                    ></app-modal-info-template>
                </div>
                <div *ngIf="selectedTabIndex === 0">
                    <div class="first-form tw-overflow-hidden">
                        <div class="form-section">
                            <span class="form-head">Account Credentials</span>
                            <hr class="tw-mt-3" />
                            <app-form-generator
                                *ngIf="userBasedOnboardingFormInput"
                                [formGeneratorInput]="
                                    userBasedOnboardingFormInput
                                "
                                (formGroupRef)="
                                    userBasedOnboardingFormGroup = $event
                                "
                            >
                            </app-form-generator>
                        </div>
                        <div class="form-section">
                            <span class="form-head">Billing Details</span>
                            <hr class="tw-mt-3" />
                            <app-form-generator
                                *ngIf="commonOnboardingFormInput"
                                [formGeneratorInput]="commonOnboardingFormInput"
                                (formGroupRef)="
                                    commonOnboardingFormGroup = $event
                                "
                                (valuesChange)="updateValidation()"
                            >
                            </app-form-generator>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="Tab.ROLE_BASED">
                <div class="tw-py-5">
                    <app-modal-info-template
                        [infoData]="infoData"
                    ></app-modal-info-template>
                </div>
                <div
                    *ngIf="selectedTabIndex === 1"
                    class="second-form tw-overflow-hidden"
                >
                    <div class="form-section">
                        <span class="form-head">Account Credentials</span>
                        <hr class="tw-mt-3" />
                        <div
                            class="tw-flex tw-justify-center tw-items-center tw-mt-4"
                        >
                            <mat-radio-group
                                aria-label="Select Onboarding Type"
                                class="tw-flex"
                                name="onboardingType"
                                (change)="onboardingType = $event.value"
                            >
                                <mat-radio-button
                                    class="tw-mx-12"
                                    [checked]="
                                        onboardingType == eOnboardType.MANUAL
                                    "
                                    value="Manual Onboarding"
                                >
                                    <span class="common-label"
                                        >Manual Onboarding</span
                                    >

                                    <i
                                        class="far fa-question-circle tw-ml-1"
                                        [matTooltip]="
                                            'Click here for information'
                                        "
                                        (click)="
                                            openManualOnboardingInfoModal()
                                        "
                                    ></i>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="tw-mx-12"
                                    [checked]="
                                        onboardingType == eOnboardType.ONECLICK
                                    "
                                    value="One-click Onboarding"
                                >
                                    <span class="common-label"
                                        >One-click Onboarding</span
                                    >
                                    <i
                                        class="far fa-question-circle tw-ml-1"
                                        [matTooltip]="
                                            'Click here for information'
                                        "
                                        (click)="
                                            openOneClickOnboardingInfoModal()
                                        "
                                    ></i>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <label *ngIf="onboardingType == eOnboardType.MANUAL">
                            <app-form-generator
                                *ngIf="manualOnboardingFormInput"
                                [formGeneratorInput]="manualOnboardingFormInput"
                                (formGroupRef)="
                                    manualOnboardingFormGroup = $event
                                "
                            >
                            </app-form-generator>
                        </label>
                        <label
                            *ngIf="onboardingType == eOnboardType.ONECLICK"
                            class="
                                create-new-role-button-area-container tw-flex tw-mt-5 tw-justify-center tw-items-center
                            "
                        >
                            <div
                                class="tw-flex tw-flex-f6 tw-justify-center tw-items-center"
                            >
                                <app-button-generator
                                    [buttonGenInput]="createButtonGenInput"
                                    (buttonRef)="createRole()"
                                ></app-button-generator>
                            </div>
                        </label>
                        <label *ngIf="onboardingType == eOnboardType.ONECLICK">
                            <app-form-generator
                                *ngIf="oneClickOnboardingFormInput"
                                [formGeneratorInput]="
                                    oneClickOnboardingFormInput
                                "
                                (formGroupRef)="
                                    oneClickOnboardingFormGroup = $event
                                "
                            >
                            </app-form-generator>
                        </label>
                    </div>
                    <div class="form-section">
                        <span class="form-head">Billing Details</span>
                        <hr class="tw-mt-3" />
                        <app-form-generator
                            *ngIf="commonOnboardingFormInput"
                            [formGeneratorInput]="commonOnboardingFormInput"
                            (formGroupRef)="commonOnboardingFormGroup = $event"
                            (valuesChange)="updateValidation()"
                        >
                        </app-form-generator>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="buttons">
        <app-multi-button-generator [buttonGenInputs]="buttonInputs">
        </app-multi-button-generator>
    </div>
</div>
