import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { UpdateAction } from 'src/app/shared/enums/UpdateAction';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IUpdateAction } from 'src/app/shared/interfaces/update-action/IUpdateAction';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { Helper } from './../../../../classes/Helper';
import { IButtonGeneratorInput } from './../../../../interfaces/button-generator/IButtonGeneratorInput';

@Component({
    selector: 'app-mca-onboarding-modal',
    templateUrl: './mca-onboarding-modal.component.html',
    styleUrls: ['./mca-onboarding-modal.component.sass']
})
export class McaOnboardingModalComponent implements OnInit {
    formGenInput: IFormGeneratorInput = null;
    formGroupRef: FormGroup;
    resetForm: Function = null;
    widgetRef: Widget;
    stepData: Object = null;
    edit: boolean;
    resetSubscription: Subscription;
    isMasterOnboarding: boolean = true;
    updateControlInput: IUpdateAction;
    updateControl: BehaviorSubject<IUpdateAction> =
        new BehaviorSubject<IUpdateAction>(null);
    buttonInputs: IButtonGeneratorInput[];

    constructor(
        private cd: ChangeDetectorRef,
        private modalInjectedData: ModalInjectedData,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData.data['widgetRef'];
        this.stepData = modalInjectedData.data['stepData'];
        this.edit = modalInjectedData.data['edit'];
        this.isMasterOnboarding =
            modalInjectedData.data['stepData'] &&
            modalInjectedData.data['stepData']['type'] !== 'MASTER'
                ? false
                : true;
    }

    ngOnInit(): void {
        this.prepareOnboardingForm();

        this.resetSubscription = this.modalService.resetModal.subscribe(
            (reset) => {
                const formData = {
                    applicationClientId: this.stepData
                        ? this.stepData['azureMCAAccountAttributes'][
                              'applicationClientId'
                          ]
                        : null,
                    secretKey: this.stepData
                        ? this.stepData['azureMCAAccountAttributes'][
                              'secretKey'
                          ]
                        : null,
                    domainId: this.stepData
                        ? this.stepData['azureMCAAccountAttributes']['domainId']
                        : null,
                    billingAccountId: this.stepData
                        ? this.stepData['accountNumber']
                        : null,
                    subscriptionId:
                        this.stepData && !this.isMasterOnboarding
                            ? this.stepData['mcaAccountsMoreInfo'][
                                  'subscriptionInfo'
                              ][0]['subscriptionId']
                            : null,
                    subscriptionName:
                        this.stepData && !this.isMasterOnboarding
                            ? this.stepData['mcaAccountsMoreInfo'][
                                  'subscriptionInfo'
                              ][0]['subscriptionName']
                            : null,
                    spoc: this.stepData ? this.stepData['spoc'] : null,
                    accountName: this.stepData
                        ? this.stepData['accountName']
                        : null,
                    profileId:
                        this.stepData && this.stepData['profileIds']
                            ? this.edit && !this.isMasterOnboarding
                                ? this.stepData['profileIds'][0]
                                : this.stepData['profileIds']
                            : null
                };
                this.resetForm(formData);
            }
        );

        this.buttonInputs = [
            {
                buttonName: 'Onboarding Steps',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.LINK,
                customClass: 'onboard-steps',
                link: this.isMasterOnboarding
                    ? 'assets/onboarding-pdf/MCA-Master-Account-Onboarding.pdf'
                    : 'assets/onboarding-pdf/MCA-Subscription-Account-Onboarding.pdf',
                function: () => {}
            },
            {
                buttonName: this.edit ? 'Update' : 'Onboard',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                customClass: 'save',
                function: (buttonRef: IButtonGeneratorInput) => {
                    this.save(buttonRef);
                }
            }
        ];
    }

    prepareOnboardingForm() {
        this.formGenInput = {
            formName: 'On Board Azure MCA Account',
            state: this.edit ? FormState.EDIT : FormState.CREATE,
            submitButton: null,
            fields: this.generateFormFields()
        };
        if (!this.edit || (this.edit && this.isMasterOnboarding)) {
            Helper.removeFormField(this.formGenInput, 'subscriptionId');
            Helper.removeFormField(this.formGenInput, 'subscriptionName');
        }
    }

    generateFormFields() {
        const fieldsMetaData = {
            applicationClientId: {
                label: 'Application (Client) ID',
                name: 'applicationClientId',
                placeholder: 'Enter Application ID',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Application ID is required'
                    }
                ],
                appearance: 'legacy',
                onChange: ($event, formGroup: FormGroup) => {
                    this.updateProfileId(formGroup);
                },
                value: this.stepData
                    ? this.stepData['azureMCAAccountAttributes'][
                          'applicationClientId'
                      ]
                    : ''
            },
            secretKey: {
                label: 'Secret Key',
                name: 'secretKey',
                placeholder: 'Enter Key',
                fieldType: FilterType.TEXT,
                required: true,
                appearance: 'legacy',
                showLabel: true,

                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Secret key is required'
                    }
                ],

                onChange: ($event, formGroup: FormGroup) => {
                    this.updateProfileId(formGroup);
                },
                value: this.stepData
                    ? this.stepData['azureMCAAccountAttributes']['secretKey']
                    : ''
            },
            domainId: {
                label: 'Domain ID',
                name: 'domainId',
                placeholder: 'Enter Domain ID',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Domain ID is required'
                    }
                ],
                value: this.stepData
                    ? this.stepData['azureMCAAccountAttributes']['domainId']
                    : '',
                onChange: ($event, formGroup: FormGroup) => {
                    this.updateProfileId(formGroup);
                },
                appearance: 'legacy'
            },
            billingAccountId: {
                label: 'Billing Account ID',
                name: 'billingAccountId',
                placeholder: 'Enter Billing Account ID',
                fieldType: FilterType.TEXT,
                required: true,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Billing Account ID is required'
                    }
                ],
                showLabel: true,
                appearance: 'legacy',
                disabled: this.edit && !this.isMasterOnboarding ? true : false,
                value: this.stepData ? this.stepData['accountNumber'] : null,
                onChange: ($event, formGroup: FormGroup) => {
                    this.updateProfileId(formGroup);
                }
            },
            profileId: {
                label: 'Billing Profile ID',
                name: 'profileId',
                placeholder: 'Enter Billing Profile ID',
                fieldType:
                    this.edit && !this.isMasterOnboarding
                        ? FilterType.TEXT
                        : FilterType.DROPDOWN_MULTIPLE,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                apiInfo:
                    this.edit && !this.isMasterOnboarding
                        ? null
                        : this.widgetRef.widgetData.widgetInfo[
                              'additionalApisForWidget'
                          ]['generateDropdown'],
                apiInput:
                    this.edit && !this.isMasterOnboarding
                        ? null
                        : this.prepareProfileIdInput(),
                disabled: this.edit && !this.isMasterOnboarding ? true : false,
                value: this.stepData
                    ? this.edit && !this.isMasterOnboarding
                        ? this.stepData['profileIds'][0]
                        : this.stepData['profileIds']
                    : null,
                validations: [
                    {
                        validator: CustomValidators.required,
                        errorMessage: 'Billing Profile ID is required'
                    }
                ]
            },
            subscriptionId: {
                label: 'Subscription ID',
                name: 'subscriptionId',
                placeholder: 'Enter Subscription ID',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                disabled: this.edit && !this.isMasterOnboarding ? true : false,
                value:
                    this.stepData && !this.isMasterOnboarding
                        ? this.stepData['mcaAccountsMoreInfo'][
                              'subscriptionInfo'
                          ][0]['subscriptionId']
                        : null
            },
            subscriptionName: {
                label: 'Subscription Name',
                name: 'subscriptionName',
                placeholder: 'Enter Subscription Name',
                fieldType: FilterType.TEXT,
                required: true,
                showLabel: true,
                appearance: 'legacy',
                disabled: this.edit && !this.isMasterOnboarding ? true : false,
                value:
                    this.stepData && !this.isMasterOnboarding
                        ? this.stepData['mcaAccountsMoreInfo'][
                              'subscriptionInfo'
                          ][0]['subscriptionName']
                        : null
            },
            spoc: {
                label: 'Account Spoc',
                name: 'spoc',
                placeholder: 'Enter Account SPOC',
                fieldType: FilterType.TEXT,
                required: false,
                showLabel: true,
                appearance: 'legacy',
                value: this.stepData ? this.stepData['spoc'] : null
            },
            accountName: {
                label: 'Account Name',
                name: 'accountName',
                placeholder: 'Enter Account Name',
                fieldType: FilterType.TEXT,
                required: false,
                showLabel: true,
                appearance: 'legacy',
                value: this.stepData ? this.stepData['accountName'] : null
            }
        };
        let fields = [];

        if (this.edit && !this.isMasterOnboarding) {
            fields.push(fieldsMetaData['billingAccountId']);
            fields.push(fieldsMetaData['profileId']);
            fields.push(fieldsMetaData['subscriptionId']);
            fields.push(fieldsMetaData['subscriptionName']);
            fields.push(fieldsMetaData['applicationClientId']);
            fields.push(fieldsMetaData['secretKey']);
            fields.push(fieldsMetaData['domainId']);
            fields.push(fieldsMetaData['spoc']);
            fields.push(fieldsMetaData['accountName']);
        } else {
            fields = Object.values(fieldsMetaData);
        }

        return fields;
    }

    updateFormGroup($event) {
        this.formGroupRef = $event;
    }

    prepareProfileIdInput() {
        const apiInput = {
            accountNumber:
                this.formGroupRef && this.formGroupRef.value['billingAccountId']
                    ? this.formGroupRef.value['billingAccountId']
                    : this.edit
                    ? this.stepData['accountNumber']
                    : null,
            azureMCAAccountAttributes: {
                applicationClientId:
                    this.formGroupRef &&
                    this.formGroupRef.value['applicationClientId']
                        ? this.formGroupRef.value['applicationClientId']
                        : this.edit
                        ? this.stepData['azureMCAAccountAttributes'][
                              'applicationClientId'
                          ]
                        : null,
                domainId:
                    this.formGroupRef &&
                    this.formGroupRef.value['applicationClientId']
                        ? this.formGroupRef.value['applicationClientId']
                        : this.edit
                        ? this.stepData['azureMCAAccountAttributes']['domainId']
                        : null,
                secretKey:
                    this.formGroupRef &&
                    this.formGroupRef.value['applicationClientId']
                        ? this.formGroupRef.value['applicationClientId']
                        : this.edit
                        ? this.stepData['azureMCAAccountAttributes'][
                              'secretKey'
                          ]
                        : null
            }
        };

        return apiInput;
    }

    updateProfileId(formGroup) {
        if (this.edit && !this.isMasterOnboarding) {
            return;
        }
        const formFieldObject = this.formGenInput.fields.find((data) => {
            return data.name === 'profileId';
        });
        formFieldObject.value = null;

        this.formGroupRef.get('profileId').setValue(null);

        formFieldObject.apiInput = {
            accountNumber: formGroup.value['billingAccountId'],
            azureMCAAccountAttributes: {
                applicationClientId: formGroup.value['applicationClientId'],
                domainId: formGroup.value['domainId'],
                secretKey: formGroup.value['secretKey']
            }
        };

        this.updateControlInput = {
            action: UpdateAction.REFRESH,
            controls: ['profileId']
        };

        if (this.formGroupRef) {
            this.updateControl.next(this.updateControlInput);
            this.cd.detectChanges();
        }
    }

    save(buttonRef: IButtonGeneratorInput) {
        if (this.formGroupRef.invalid) {
            Helper.markAllFieldAsTouched(this.formGroupRef);
            return;
        }
        buttonRef.loader = true;
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.edit
                ? this.widgetRef.widgetData.widgetInfo.update
                : this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.generateInputData();

        apiArgs.function = () => {
            buttonRef.loader = false;
            this.modalService.closeModal(null, this.modalInjectedData.modalId);
            this.widgetRef.notificationsService.showSnackBar(
                this.edit
                    ? 'Account Edited Successfully'
                    : 'Account Onboarded Successfully'
            );
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            buttonRef.loader = false;
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                this.edit
                    ? 'Error while updating account'
                    : 'Error while onboarding account'
            );
            this.widgetRef.refreshWidget();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }

    generateInputData() {
        const input = {
            accountNumber: this.formGroupRef.value['billingAccountId'],
            spoc: this.formGroupRef.value['spoc'],
            accountName: this.formGroupRef.value['accountName'],
            billingProfileIds:
                this.edit && !this.isMasterOnboarding
                    ? [this.formGroupRef.getRawValue()['profileId']]
                    : this.formGroupRef.getRawValue()['profileId'],
            azureMCAAccountAttributes: {
                applicationClientId:
                    this.formGroupRef.value['applicationClientId'],
                domainId: this.formGroupRef.value['domainId'],
                secretKey: this.formGroupRef.value['secretKey']
            }
        };

        if (this.edit && !this.isMasterOnboarding) {
            input['azureMCAAccountAttributes']['subscriptionId'] =
                this.formGroupRef.getRawValue()['subscriptionId'];

            input['azureMCAAccountAttributes']['subscriptionName'] =
                this.formGroupRef.getRawValue()['subscriptionName'];
        }

        if (this.edit) {
            input['type'] = this.isMasterOnboarding ? 'MASTER' : 'SUBSCRIPTION';
            return [input];
        } else {
            return input;
        }
    }

    ngOnDestroy() {
        this.resetSubscription.unsubscribe();
    }
}
